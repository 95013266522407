// app/javascript/packs/posts.js

import 'bootstrap';
import Rails from '@rails/ujs';


import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js';




window.util = window.util || {};

// Define the 'photos' object within the 'util' namespace
window.util.photos = {
    bindLightbox: function() {
                
        const lightbox = new PhotoSwipeLightbox({
            gallery: '.my-gallery',
            children: 'a.lightbox',
            pswpModule: () => import('photoswipe/dist/photoswipe.esm.js')
        });
        lightbox.init();
        
    },
    removePhoto: function(photoId)
    {
        $.ajax({
            type: "DELETE", // Use the DELETE HTTP method
            url: "/photos/" + photoId, // Adjust this to your actual photo resource endpoint
            success: function (data) {
            console.log("File removed successfully", data);
            },
            error: function (error) {
            console.error("Error removing file", error);
            },
        });
    },
    refreshIndex: function() {
        $.ajax({
            type: "GET",
            url: "/photos/", // Adjust this to your actual photo resource endpoint
            success: function (data) {
            console.log("File removed successfully", data);
            },
            error: function (error) {
            console.error("Error removing file", error);
            },
        });
    },
    // add a photo to the selector, called after dropzone upload
    addToBySelector: function(selector,result)
    {
        // if result is json, fetch html
        let notification;
        if(result.notification) {
            notification = siteUi.showNotification(result.notification,7000);         
        }
        if(result.html) {
            $(selector).prepend(result.html)
        }
    },
    bindPanel: function() {
        const panel = document.getElementById('photos-panel');
        //const toggleBtn = document.getElementById('toggle-panel-btn');
        const toggleBtns = document.querySelectorAll('.toggle-panel')
        
        toggleBtns.forEach((btn) => {
            
            btn.addEventListener('click', function () {
                //TODO: panel should be retrieved by btn data-toggle-target
                const panelWidth = panel.offsetWidth;
            
                // Toggle the panel by adjusting the right position
                const panelOpen = panel.style.right === '0px';
                panel.style.right = panelOpen ? `-${panelWidth}px` : '0px';

                //if(btn.classList.contains('toggle-panel-btn'))                
                toggleBtns.forEach((btnToStyle) => {
                    if(panelOpen)
                    {
                        btnToStyle.classList.remove('panelOpen');
                    }
                    else {
                        btnToStyle.classList.add('panelOpen');
                    }
                });                

            });
        })
       
        
    },
    bindDraggables: function() {
        console.log('inside bindDraggables');
    
        const draggableLists = document.querySelectorAll('.draggable-list');
        const dropSections = document.querySelectorAll('.drop-section');
    
        draggableLists.forEach(function(draggableList) {
          console.log('Initializing Draggable for:', draggableList);
          const draggable = new Draggable.Droppable(draggableList, {
            draggable: '.draggable-item',
            dropzone: dropSections,
          });
    
          draggable.on('drag:stop', function(event) {
            console.log('drag stopped');
            const draggedItemId = event.data.originalSource.dataset.id;
            const dropSection = event.dropzone;
            console.log('Dropped photo with ID:', draggedItemId, 'into section:', dropSection);
            window.util.photos.handleDroppedPhoto(draggedItemId, dropSection);
          });
        });
      },
    // Your custom function to handle the dropped photo
    handleDroppedPhoto: function (photoId, dropSection) {
      // Add your logic here based on the dropped photo ID and drop section
      console.log('Dropped photo with ID:', photoId, 'into section:', dropSection);
      // You can make an AJAX request or perform any other actions as needed
    }
}
/*
// Exports
window.util.photos = {
    removePhoto, refreshIndex, addToBySelector
};
*/ 